import React from 'react'
import styled from 'styled-components'
import { mq } from 'core/theme'

export const SidebarLogo = () => (
    <Container>
        <SVG viewBox="0 0 1217 249" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g fill="#FFF6E6" clipPath="url(#clip0_520_168)">
                <path d="M257 118.606c3.007-58.441 50.011-105.445 108.452-108.453v108.453H257zM377.367 10.003H485.82c-3.007 58.44-50.011 105.445-108.452 108.452l-.001-108.452zM257 239.128c3.007-58.442 50.011-105.446 108.452-108.453v108.453H257zM377.367 130.523H485.82c-3.007 58.442-50.011 105.446-108.452 108.453l-.001-108.453zM492.086 118.606c3.007-58.441 50.011-105.445 108.452-108.453l.001 108.453H492.086zM612.457 10.003h108.452c-3.007 58.44-50.011 105.445-108.452 108.452V10.003zM492.086 239.128c3.007-58.442 50.011-105.446 108.452-108.453l.001 108.453H492.086zM612.457 130.523h108.452c-3.007 58.442-50.011 105.446-108.452 108.453V130.523zM10 118.606C13.007 60.165 60.011 13.161 118.452 10.153v108.453H10zM238.975 10c-3.007 58.442-50.011 105.446-108.452 108.453V10h108.452zM118.452 238.976C60.012 235.969 13.007 188.965 10 130.523h108.452v108.453zM130.523 130.675c58.442 3.007 105.446 50.011 108.453 108.453H130.523V130.675zM804.506 67.272c0 65.699-70.903 76.757-70.903 143.757V239H837.03v-32.524h-67.651c-.325-1.626-.325-3.253-.325-4.879 0-37.403 71.228-64.073 71.228-135.626 0-36.427-18.213-57.243-53.34-57.243-35.126 0-53.339 20.816-53.339 57.243v22.117h33.825V63.694c0-16.262 7.155-22.441 18.539-22.441 11.383 0 18.539 5.529 18.539 26.02zm90.9-3.578c0-16.262 7.155-22.441 18.538-22.441 11.384 0 18.539 6.18 18.539 22.441v122.942c0 16.262-7.155 22.442-18.539 22.442-11.383 0-18.538-6.18-18.538-22.442V63.694zM859.629 184.36c0 36.427 19.189 57.243 54.315 57.243 35.127 0 54.316-20.816 54.316-57.243V65.971c0-36.427-19.189-57.243-54.316-57.243-35.126 0-54.315 20.816-54.315 57.243V184.36zM1062.41 67.272c0 65.699-70.9 76.757-70.9 143.757V239h103.43v-32.524h-67.65c-.33-1.626-.33-3.253-.33-4.879 0-37.403 71.23-64.073 71.23-135.626 0-36.427-18.21-57.243-53.34-57.243s-53.34 20.816-53.34 57.243v22.117h33.82V63.694c0-16.262 7.16-22.441 18.54-22.441 11.39 0 18.54 5.529 18.54 26.02zm80.17-1.951h27.65V239H1206V11.33h-24.07c-5.53 15.287-11.7 28.622-39.35 28.622V65.32z"></path>
            </g>
            <defs>
                <clipPath id="clip0_520_168">
                    <path fill="#fff" d="M0 0H1217V249H0z"></path>
                </clipPath>
            </defs>
        </SVG>
    </Container>
)

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 67px;
`

const SVG = styled.svg`
    width: 60%;
`

export default SidebarLogo
